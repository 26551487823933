// ----------------
// КЛАССЫ ДЛЯ TRANSITIONS
// ----------------

@use 'sass:map';

$work: (
  "time": true,
  "translate": false,
);


// TIME
// ^^^^^
@if map.get($work, "time") {
  @each $time in $myTransitionTime {
    $str: $time + unquote($string: ""); //number -> string
    $mark: str-replace($str, '.'); // replace dot '.'
    $mark: str-replace($mark, 's'); // replace 's'
    .trnT-#{$mark} {                // 'trnT-03'
      transition: $time all ease;
    }
  }
}

// !!! in 'utils/after/_hovers.scss' !!!
// SCALE
// @each $scale in $myTransitionScale {
//   $str: $scale + unquote($string: ""); //number -> string
//   $mark: str-replace($str, '.'); // replace dot '.'
//   .trnS-#{$mark} {                // 'trnT-03'
//     transition: scale($scale);
//   }
// }

// TRANSLATE
// ^^^^^^^^^^
@if map.get($work, "translate") {
  @each $trans in $myTransitionTranslate {
    $str: $trans + unquote($string: ""); //number -> string
    // $mark: str-replace($str, '.'); // replace dot '.'
    $mark: str-replace($str, '%'); // replace '%'
    $ptrans: "(" + $trans + ")";

    @each $dim in (X, Y) {
      .trn#{$dim}-#{$mark} {                // 'trnY-100'
        transform: translate#{$dim}#{$ptrans};
      }

      // HOVER (ПРЕДОК - ПОТОМОК)
      // ans - ansector (предок)
      // des - descendant (потомок)

      // .trn#{$dim}-#{$mark}-ans
      // .trn#{$dim}-#{$mark}-des {
        // transform: translate#{$dim}#{$ptrans};
      // }

      .trn#{$dim}-#{$mark}-ans:hover
      .trn#{$dim}-#{$mark}-des {
        transform: translate#{$dim}#{$ptrans};
      }
    }
}

}
