// --------------
// ПЕРЕМЕННЫЕ scss ДЛЯ ПРОЕКТА
//   1. FONTS
//   2. COLORS
//   3. Z-INDEX
//   4. ABSOLUTE
//   5. OVERLAY
//   6. SOCIALS
//   7. SIZES
//   8. TRANSITIONS
//   9. LINKS
//   10. MEDIA OBJECTS
//   11. POSITION
//   12. BREAKPOINTS
//   13. IMAGES
//   14. FOCUS
//   15. OWL.CAROUSEL
//   16. HERO
//   17. BACKGROUND
//   18. SKEW
//   19. ORIGIN
//   20. OBJECT-FIT
//   21. ICONS
//   22. PADDINGS
// --------------

// --------------
// 1. FONTS
// --------------
  // FONT-SIZES
  // ^^^^^^^^^^^^^
  // ::utils/after/_font-size.scss
  $myFontSizes: (
    // 0.7rem, 
    // 0.8rem, 
    0.9rem, 
    // 1.0rem, 
    // 1.1rem, 
    1.20rem,
    // 1.25rem, 
    // 1.3rem, 
    // 1.4rem,
    // 1.5rem, 
    // 1.7rem, 
    // 2.0rem, 
    // 2.2rem,
    // 2.5rem,
    3.0rem,
    // 4.0rem,
    // 5.0rem
  );

  // letter-spacing
  // ^^^^^^^^^^^^^^^
  // $myLetter-spacing: (
    // 0.1em,
    // 0.2em, 
    // 0.3em, 
    // 0.4em, 
    // 0.5em
    // 0.8em
  // );

  // WEIGHT
  // ^^^^^^^
  $myFontWeight: (
    // 100,
    // 200,
    // 300,
    // 400,
    // 500,
    // 600,
    // 700,
    // 800,
    900,
  );


// --------------
// 2. COLORS
// --------------

  // Gray colors
  // ^^^^^^^^^^^^^
  // :: utils/after/_svg.scss
  $myGrays: (
    // "100": $gray-100,
    "200": $gray-200,
    // "300": $gray-300,
    // "400": $gray-400,
    // "500": $gray-500,
    "600": $gray-600,
    // "700": $gray-700,
    // "800": $gray-800,
    "900": $gray-900
  );


  // MY Hover colors
  // ^^^^^^^^^^^^^^^^
  // :: utils/after/_hovers.scss
  $myHoverColors: (
      // "white": $white,
      // "primary": $primary,
      // "secondary": $secondary,
      // "100": $gray-100,
      "gray200": $gray-200,
      // "300": $gray-300,
      // "gray400": $gray-400,
      // "gray500": $gray-500,
      // "gray600": $gray-600,
      // "700": $gray-700,
      // "800": $gray-800,
      // "900": $gray-900
  );


  // MY FILL colors
  // ^^^^^^^^^^^^^^^^
  // :: utils/after/_svg.scss
  $myFillColors: (
    "primary": $primary,
    // "secondary": $secondary,
  );

  // ALPHA
  // ^^^^^^
  // :: utils/after/_font-color.scss
  $myAlpha: (
    // 0.1,
    0.2,
    // 0.3,
    0.4,
    // 0.5,
    // 0.6,
    // 0.7,
    0.8,
    // 0.9,
    1.0
  );

  // TEXT Color
  // ^^^^^^^^^^^
  // :: utils/after/_font-color.scss
  $myColorText: (
    // "white": $white,
    // "black": $black,
    // "gray900": $gray-900,
    "secondary": $secondary,
  );

  // BUTTONS
  // ^^^^^^^^^
  $myButtonColor: rgba($white, 1);
  $myButtonColorHover: $gray-900;
  $hover-background: transparent; // name of var is from bootstrap
  $hover-border: $primary;  // name of var is from bootstrap


// --------------
// 3. Z-INDEX

// --------------
// :: utils/after/_z-index.scss
  $myZIndex: (
    // 1,
    10,
    20,
    // 30,
    // 40,
    // 50,
    // 60,
    // 70,
    // 80,
    // 90,
    // 100,
  );


// --------------
// 4. ABSOLUTE
// --------------
  $myAbsPoints: (
    0%,
    5%,
    // 10%,
    // 25%,
    // 35%,
    50%,
    // 75%,
    // 100%
  );


// --------------
// 5. OVERLAY
// --------------

  // FOR HERO
  // ^^^^^^^^^^
  $myOverlay: rgba(0,0,0,.5);


// --------------
// 6. SOCIALS
// --------------

  // dimensions
  // ^^^^^^^^^^^
  $mySocialsTopBarWidth: 1.2em;
  $mySocialsTopBarHeight: 1.2em;


// --------------
// BORDERS
// --------------

  // width
  // ^^^^^^^
  // $myBorderWidth: (
    // 2px,
    // 3px,
    // 4px,
    // 5px
  // );


// --------------
// 7. SIZES
// --------------
  // MY SIZES IN EM (height, width)
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  // :: sass/utils/after/_sizes.scss
  $mySizesEm: (
    // 0em,
    0.5em,
    0.8em,
    // 1em,
    // 1.75em,
    // 2em,
    3em,
    // 3.5em,
    // 4em,
    // 4.5em,
    // 5em,
    // 6em,
    7em,
    // 8em,
    // 9em,
    // 10em,
    // 11em,
    // 12em,
    // 13em,
    // 14em,
    // 15em,
    // 18em,
    23em,
    42em,
  );

  // MY SIZE CORRECTION (MINUS) OF WIDTH/HEIGHT IN EM
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  // $mySizeCorrect: (  // '_bootstrap/_sizing.scss'
    // -1em,
  // );

  // MY SIZE CORRECTION (MINUS) OF WIDTH/HEIGHT IN PX
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  // $mySizeCorrectPX: (
    // 1px: 1px,
  // );

  // MY SIZES IN PX
  // ^^^^^^^^^^^^^^^
  // $mySizesPx: (
    // 1px,
    // 2px,
    // 3px
  // );

  // MY SIZES IN pc (%)
  // ^^^^^^^^^^^^^^^^^^^
  // $mySizesProCent: (
    // 1%,
    // 40%
  // );

  // MY SIZES IN PC (%) for WIDTH/HEIGHT
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  $mySizes4Parts: (
    // 0: none,
    // 25: 25,
    // 50: 50,
    // 75: 75,
    100: 100
  );

  // MY SIZES PLAIN (line height)
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  // $mySizesPlain: (
    // normal,
    // 1.2,
    // 1.3,
    // 1.5,
    // 2,
    // 3
  // );

  // MY SIZES FLEX-BASIS IN %
  // ^^^^^^^^^^^^^^^^^^^^^^^^^
  // $myFlexBasisProCent: (
    // 0%,
    // 25%,
    // 50%,
    // 75%,
    // 100%,
  // );

  // MY SIZES VH & VW
  // ^^^^^^^^^^^^^^^^^
  // $myHeightVH: (
    // 50vh,
    // 90vh,
  // );


// --------------
// 8. TRANSITIONS
// --------------

  // TIME
  // ^^^^^^^
  // :: utils/after/_transitions.scss
  $myTransitionTime: (
    // 0.0s,
    0.3s,
    // 0.5s
    // 2.0s
  );
  
  // SCALE
  // ^^^^^^^
  // $myTransitionScale: (
    // 1.05,
  // );

  // TRANSLATE
  // ^^^^^^^^^^
  // $myTransitionTranslate: (
    // 0%,
    // 25%,
    // 50%,
    // 75%,
    // 100%
  // );


// --------------
// 9. LINKS
// --------------

  // COLOR
  // ^^^^^^^
  // $myNavLinkColor: $gray-500;
  // $myNavLinkColorHover: $gray-300;
  // $myNavLinkColorInvert: $gray-600;
  // $myNavLinkColorInvertHover: $gray-400;

  // SVG
  // ^^^^^
  // $myNavLinkSVGColor: $gray-500;
  // $myNavLinkSVGColorHover: $white;
  // $myNavLinkSVGColorHover: $secondary;
  // $myNavLinkSVGColor2: $gray-500;
  // $myNavLinkSVGColorHover2: $gray-400;
  // $myNavLinkSVGColorHover2: $secondary;


// --------------
// 10. MEDIA OBJECTS
// --------------

  // SECTIONS
  // ^^^^^^^^^
    // WhichServices: WS
    // :::::::::::::::::::
    // $myWSBackground: rgba($dark, .7); // background-color
    // $myWSBackground-Md: rgba($dark, .4); // background-color
    // $myWSBackgroundHover: $white;  // bachround-color:hover
    // $myWSHeader: $primary;  // header-color
    // $myWSHeaderHover: $gray-500;  // header-color-hover
    // $myWSText: rgba($white, .8); // text-color
    // $myWSText-Md: rgba($white, .5); // text-color
    // $myWSTextHover: $gray-500; // text-color



// --------------
// 11. POSITION
// --------------
  // $myPositionsList: (
    // absolute,
    // relative
  // );

// --------------
// 12. BREAKPOINTS
// --------------
  $myBreakpointsList: (
    // "lg"
  );

// --------------
// 13. IMAGES
// --------------

    // Borders
    // ^^^^^^^^^
    // $myIMGborderStyle: solid;  // border-style
    // $myIMGborderWidth: 0.3em;  // border-width
    // $myIMGborderColor: $primary;  // border-color

    // Borders 1.3em
    // ^^^^^^^^^^^^^^^
    // $border_1_3em_classes: (
    // ".why-us-flag .img-border-before", 
    // ".projects-flag .img-border-before",
    // ".category_arguments_layout-flag .img-border-before",
    // ".sw-flag .img-border-before",
    // ".id-goals .img-border-before", 
    // );

    // $myIMGtop-13em: 1.3em;  // top
    // $myIMGleft-13em: 1.3em;  // left
    // $myIMGright-13em: 1.3em;  // right
    // $myIMGbottom-13em: 1.3em;  // bottom

    // Borders 0.6em
    // ^^^^^^^^^^^^^^^
    // $border_0_6em_classes: (
    // ".ourteam-flag .img-border-asc .img-border-des",
    // "#post-authors .img-border-asc .img-border-des",
    // );

    // $myIMGtop-06em: 0.6em;  // top
    // $myIMGleft-06em: 0.6em;  // left
    // $myIMGright-06em: 0.6em;  // right
    // $myIMGbottom-06em: 0.6em;  // bottom

    // Borders:HOVER
    // ^^^^^^^^^^^^^^
    // $border_hover_classes: (
    // ".ourteam-flag .img-border-asc:hover .img-border-des",
    // "#post-authors .img-border-asc:hover .img-border-des",
    // );

// --------------
// 14. FOCUS
// --------------

  // TEXT
  // ^^^^^^
  // $myFocusTextColor: $primary;
  // $myFocusTextBackgroundColor: $transparent;


// --------------
// 15. OWL.CAROUSEL (O)
// --------------

  // DOTS (D)
  // ^^^^^^^^
    // testimonials (Test)
    // :::::::::::::::::::::
    // $myODTestBGColor: $gray-300;
    // $myODTestBGColorActive: $primary;
    // $myODTestWidth: 0.5em;
    // $myODTestHeight: 0.5em;
    // $myODTestBorderRadius: 50%;
    // $myODTestBorder: none;
    // $myODTestMargin: 0.3em;

// --------------
// 16. HERO
// --------------

  // MY SIZES OF HERO
  // ^^^^^^^^^^^^^^^^^
    // MAIN PAGE
    // """"""""""
    // $myMinHeightForHeroMainPage: 600px;

    // MIN HEIGHT
    // """""""""""
    $myMinHeroHeight: 400px;


// --------------
// 17. BACKGROUND
// --------------

  // BACKGROUND COLORS
  // ^^^^^^^^^^^^^^^^^^^^^
  // $myBackgroundColors: (
    // "black": $black,
    // "gray200": $gray-200,
    // "gray600": $gray-600,
    // "primary": $primary,
  // );

  // SIZE
  // ^^^^^^
  $myBackgroundSize: (
    cover,
    contain
  );

  // POSITION
  // ^^^^^^^^^^
  $myBackgroundPosition: (
  //  "lt": "left top",
  //  "lc": "left center",
  //  "lb": "left bottom",
  //  "rt": "right top",
  //  "rc": "right center",
  //  "rb": "right bottom",
  //  "ct": "center top",
   "cc": "center center",
  //  "cb": "center bottom"
  );

  // BACKGROUND ORIGIN
  // ^^^^^^^^^^^^^^^^^^
  // $myBackgroundOrigin: (
    // "padd": "padding-box",
    // "bord": "border-box",
    // "cont": "content-box",
  // );

  // CLIP
  // ^^^^^^
  // $myBackgroundClip: (
    // "padd": "padding-box"
  // );

  // GRADIENT LINEAR
  // ^^^^^^^^^^^^^^^^
  $myGradLinear_1: (
    "direction": to right,
    "startColor": $secondary,
    "startPos": 0%,
    "endColor": $primary,
    "endPos": 100%,
  );

// --------------
// 18. SKEW
// --------------
  // $mySkewDegree: (
    // 8deg
  // );


// --------------
// 19. ORIGIN
// --------------
  // $myOrigin: (
    // 'top right'
  // );


// --------------
// 20. OBJECT-FIT
// --------------
  $myObjectFit: (
    'cover',
    'contain',
  );

  $myObjectPosition: (
    'center'
  );


// --------------
// 21. ICONS
// --------------

  // SECTIONS
  // ^^^^^^^^^^
    // :: sass/modules/icons/icons.scss
    // $myIPIconWidth: 3.75em;     // width of icon
    // $myIPIconHeight: 3.75em;    // height of icon
    // $myIPIconBorderRadius: 5%;  // border-radius of icon
    // $myIPIconBackgroundColor: $secondary; // color of bg of icon
    // $myIPIconTop: .7em;         // top of icon
    // $myIPIconLeft: 1em;         // left of icon
    // $myIPIconZindex: -1;        // z-index of icon

  // LIST MARK
  // ^^^^^^^^^^
    // :: sass/modules/icons/icons.scss
    // CHECK MARK
    $check-mark: (
      "width": 0.5rem,
      "height": 0.5rem,
      "border-radius": 0,
      "back-color": transparent,
      "top": 0.3rem,
      "left": 0,
      "z-index": 0,
    );

    $myListMark: ("check-mark": $check-mark);

// --------------
// 22. PADDINGS
// --------------

// STANDARD PADDING
// ^^^^^^^^^^^^^^^^^
$paddingStandard:  ("std": 15px);
