@use 'sass:map';

$work: (
  "size": true,
  "weight": true,
);


// ----------------
// КЛАССЫ ДЛЯ РАЗМЕРА ШРИФТА
// ----------------
@if map.get($work, "size") {
    // Скопировано: bootstrap/sass/utilities/_display.scss
    //---------------------------
  @each $breakpoint in map.keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      @each $size in $myFontSizes {
        $str: $size + unquote("");       // number -> string
        $mark: str-replace($str, '.', '_');   // replace dot '.'
        $mark: str-replace($mark, 'rem'); // replace 'rem'
        .fsz#{$infix}-#{$mark} {                  // .fsz-11 (1.1rem)
          font-size: $size !important;              // font-size: 1.1rem
        }
      }
    }
  }
}

// ----------------
// КЛАССЫ ДЛЯ ТОЛЩИНЫ ШРИФТА
// ----------------
@if map.get($work, "weight") {
  @each $breakpoint in map.keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      @each $size in $myFontWeight {
        $mark: $size;
        .fwgt#{$infix}-#{$mark} {            // .fsz-100 (100)
          font-weight: $size;                 // font-weight: 1.1
        }
      }
    }
  }
}
