@use 'sass:map';

// ----------------
// КЛАССЫ ДЛЯ MIN/MAX РАЗМЕРОВ
// ----------------

  // Скопировано: bootstrap/sass/utilities/_sizes.scss
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  // ::::::::::::::::::::::
  $work: (
    "em": true,
    "procent": false,
    "vh": false,
    "lineHeight": false,
    "bootstrap": true,
  );
  // ::::::::::::::::::::::



  // EM
  // ^^^
  @if map.get($work, "em") {

    // XS
    // ^^^
    @each $size in $mySizesEm {
      $str: $size + unquote("");           // number -> string
      $mark: str-replace($str, ".", "_");  // remove dot "." for "_"

      .minW-#{$mark} {                     // minW-2em
        min-width: $size;
      }

      .maxW-#{$mark} {
        max-width: $size;
      }

      .minH-#{$mark} {
        min-height: $size;
      }

      .maxH-#{$mark} {
        max-height: $size;
      }

      .wem-#{$mark} {
        width: $size;
      }

      .hem-#{$mark} {
        height: $size;
      }
    }

    // MD, LG
    // ^^^^^^^
    @each $breakpoint in map.keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @if $infix != "" {                           // if $infix != '-xs'
          // -md- -lg-
          @each $size in $mySizesEm {
            $str: $size + unquote("");               // number -> string
            $mark: str-replace($str, ".", "_");      // remove dot "."

            .minW#{$infix}-#{$mark} {                // minW-2em
              min-width: $size;
            }

            .maxW#{$infix}-#{$mark} {
              max-width: $size;
            }

            .minH#{$infix}-#{$mark} {
              min-height: $size;
            }

            .maxH#{$infix}-#{$mark} {
              max-height: $size;
            }

            .wem#{$infix}-#{$mark} {
              width: $size !important;
            }

            .hem#{$infix}-#{$mark} {
              height: $size !important;
            }
          }
        }
      }
    }
  }
  

  // ProCents %
  // ^^^^^^^^^^^
  @if map.get($work, "procent") {
    @each $size in $mySizesProCent {
      $str: $size + unquote("");     // number -> string
      $mark: str-replace($str, "%", "pc"); // remove "%"

      .minW-#{$mark} {                // minW-1pc
        min-width: $size;
      }

      .maxW-#{$mark} {
        max-width: $size;
      }

      .minH-#{$mark} {
        min-height: $size;
      }

      .maxH-#{$mark} {
        max-height: $size;
      }

      .wpc-#{$mark} {  // pc - %
        width: $size;
      }

      .hpc-#{$mark} {  // pc - %
        height: $size;
      }
    }
  }

  // VH
  // ^^^^
  @if map.get($work, "vh") {
    @each $size in $myHeightVH {
      // $str: $size + unquote("");     // number -> string
      // $mark: str-replace($str, "%", "pc"); // remove "%"

      // .maxVH-#{$mark} {                // maxVH-100vh
      .maxVH-#{$size} {                // maxVH-100vh
        max-height: $size;
      }
    }
  }

  // LINE HEIGHT
  // ^^^^^^^^^^^^
  @if map.get($work, "lineHeight") {
    @each $size in $mySizesPlain {
      $str: $size + unquote("");     // number -> string
      $mark: str-replace($str, ".", "_"); // remove "%"

      .lh-#{$mark} {                // lh-1_2
        line-height: $size;
      }
    }
  }


  // ----------------------
  // В этом разделе мы расширим классы Bootstrap
  // и добавим новые
  // '.w' - добавим значения для разных экранов
  // '.vh' - добавим vh-25, vh-50, vh-75
  // '.vh' - добавим значения для разных экранов
  // -----------------------

@if map.get($work, "bootstrap") {
  @each $breakpoint in map.keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @if $infix != "" {  // if $infix != '-xs'
        @each $prop, $abbrev in (width: w, height: h) {  // w-lg-25
          @each $size, $length in $mySizes4Parts {
            .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length#{'%'} !important; }
          }
        }
      }

      @each $prop, $abbrev in (/*width: w,*/ height: vh) { // vh-lg-100
        @each $size, $length in $mySizes4Parts {
          @if $size != 0 {
            .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length + $abbrev !important; }
          } @else {
            .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
          }
        }
      }
    }
  }
}
