// --------------
// 'a'
// --------------
a {
  transition: .3s all ease;

  &:hover {
    //text-decoration: none;
    cursor: pointer;
  }
}

// --------------
// 'ul'
// --------------
ul {
  list-style-type: none;
}

// --------------
// 'input', 'textarea'
// --------------
:focus {
  outline: 1px solid $primary;
}

// --------------
// 'btn:focus'
// --------------
// .btn-primary:focus {
//   color: var(--secondary) !important;
// }
