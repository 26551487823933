// -----------------
// СОЗДАЕТ КЛАССЫ HOVERS РАЗНЫХ ЦВЕТОВ
// -----------------

@use 'sass:map';

$work: (
  "text_svg": false,
  "background": true,
  "scale": false,
);

// --------------
// CODE
// --------------

@if map.get($work, "text_svg") {
  // TEXT COLOR, SVG
  @each $color, $value in $myHoverColors {
    @each $alpha in $myAlpha {
      $mark: $alpha + quote("");        // number -> string
      $mark: str-replace($mark, ".", "_");   // remove "."

      .hvr-#{$color}_a#{$mark}:hover {   // '.hvr-blackA02'
        color: rgba($value, $alpha) !important;
        fill: rgba($value, $alpha);
      }


      .hvr-#{$color}_a#{$mark}-anc:hover 
      .hvr-#{$color}_a#{$mark}-desc
      {   // '.hvr-black_a0_2-anc .hvr-black_a0_2-desc'
        color: rgba($value, $alpha) !important;
        fill: rgba($value, $alpha);
      }
    }
  }
}

@if map.get($work, "background") {
  // BACGROUND COLOR
  @each $color, $value in $myHoverColors {
    @each $alpha in $myAlpha {
      $mark: $alpha + quote("");        // number -> string
      $mark: str-replace($mark, ".", "_");   // remove "."
      .hvr-bg-#{$color}_a#{$mark}:hover {          // '.hvr-bg-black_a0_2'
        background-color: rgba($value, $alpha);
      }
    }
  }
}

@if map.get($work, "scale") {
  // SCALE
  @each $scale in $myTransitionScale {
    $str: $scale + unquote($string: "");  //number -> string
    $mark: str-replace($str, '.');        // replace dot '.'
    .trnS-img-#{$mark}:hover img {        // 'trnS-13'
      transform: scale($scale);
    }
  }
}

