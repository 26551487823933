// --------------------
// HERO CLASSES
// --------------------

@use 'sass:map';

@mixin heroHeight ($min-height) {
  min-height: $min-height;
}

// ----------------
// $WORK
// ----------------
$work: (
  "hero": true,
  "hero-light": false,
  "hero-row": false,
);

// HERO
// ^^^^^^
@if map.get($work, "hero") {
  .hero {
    // Пример отсюда: https://dev.to/selbekk/how-to-overlay-your-background-images-59le
    box-shadow: inset 0 0 0 100vw $myOverlay;
    background-image: var(--hero-img-min);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: calc(var(--hero-h-min) - var(--hero-h-minus));
    min-height: $myMinHeroHeight;

    @media screen and (min-width: map.get($grid-breakpoints, "sm")) {
      background-image: var(--hero-img-mid);
      height: calc(var(--hero-h-mid) - var(--hero-h-minus));
    }

    @media screen and (min-width: map.get($grid-breakpoints, "lg")) {
      background-image: var(--hero-img-max);
      height: calc(var(--hero-h-max) - var(--hero-h-minus));
    }
  }
}

@if map.get($work, "hero-light") {
  .hero-light {
    // Пример отсюда: https://dev.to/selbekk/how-to-overlay-your-background-images-59le
    // box-shadow: inset 0 0 0 100vw $myOverlay5;
    background-image: var(--hero-img);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@if map.get($work, "hero-row") {
  .hero .hero-row {
    @include heroHeight(
      $myMinHeightForHeroMainPage,
    );
  }
}
