// ----------------
// КЛАССЫ ДЛЯ OBJECT-FIT
// ----------------

@each $object in $myObjectFit {
  .objectFit-#{$object} {
    object-fit: #{$object};
  }
}

@each $object in $myObjectPosition {
  .objectPosition-#{$object} {
    object-position: #{$object};
  }
}
