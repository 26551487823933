// --------------
// FONTS
// --------------
  //** font-family
  // $mycustom-font: "Nunito Sans";
  $mycustom-font: "Nunito";
  $font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  $font-family-base: $mycustom-font, $font-family-sans-serif;

// --------------
// COLORS
// --------------
  // ** theme-colors
  // $primary: #F77B2E;
  $primary: #36B1BF;
  // $secondary: #7a5e86;
  // $secondary: #5D49A6;
  $secondary: #4B64F2;

  // ** other
  $transparent: rgba(0, 0, 0, 0);

// LINKS
  // ** link-hover
  $link-hover-decoration: none;

// --------------
// BUTTONS
// --------------
  // ** btn-colors
  $btn-border-width: 2px;
  $btn-border-radius: 0rem;
  $btn-color-hover: $secondary;

// --------------
// SPACING
// --------------
  $spacer: 1rem;
  $spacers: (
    6: ($spacer * 5),
    // 7: ($spacer * 6),
    8: ($spacer * 7),
  );
