// ----------------
// КЛАССЫ ДЛЯ ABSOLUTE
// ----------------


// :::::::::::::::::::::::::::::::
// STYLEGUIDE
// :::::::::::::::::::::::::::::::

// UTILS
// 
// Небольшие блоки кода, создающие классы для конкретных
// свойств элементов (не модули с большим кол-ом свойств)
// 
// Styleguide UTILS

// AFTER
// 
// Классы, определенные после библиотеки BOOTSTRAP
// 
// Styleguide UTILS.AFTER


// ABSOLUTE  
//
// Элементы: **POSTITION-ABSOLUTE**
//   
// ---------------------------------
// Шаблон: **abs** { _t/r/b/l_ } - '{n}?'
// ---
// 
//
// **_Указывает расстояние элемента (в %) от границ родительского блока_**
//
// - _t_--top
// 
// - _r_--right
// 
// - _b_--bottom
// 
// - _l_-- left
// 
// - _n_-- not (minus)
//
// - _?_--расстояние (в %)
//
// - Значения '?' задаются в переменной vars_after::**$myAbsPoints**
// 
// Styleguide UTILS.AFTER.ABSOLUTE 

// SIMPLE
//
// Простые случаи
//
//  Markup:
//	<div class="row">
//	  <div class="col-1"><!-- abs{b}-0 -->
//	    <span class="d-block my-1">abs<em class="text-secondary">b</em>-0</span>
//	    <div class="border border-primary position-relative wem-45em hem-45em">
//	      <div class="absb-0 bg-secondary wem-08em hem-08em position-absolute"></div>
//	    </div>
//	  </div><!-- abs{b} - 0 -->
//	  <div class="col-1"><!-- abs{t}-0 -->
//	    <span class="d-block my-1">abs<em class="text-secondary">t</em>-0</span>
//	    <div class="border border-primary position-relative wem-45em hem-45em">
//	      <div class="abst-0 bg-secondary wem-08em hem-08em position-absolute"></div>
//	    </div>
//	  </div><!-- abs{t}-0 -->
//	  <div class="col-1"><!-- abs{l}-0 -->
//	    <span class="d-block my-1">abs<em class="text-secondary">l</em>-0</span>
//	    <div class="border border-primary position-relative wem-45em hem-45em">
//	      <div class="absl-0 bg-secondary wem-08em hem-08em position-absolute"></div>
//	    </div>
//	  </div><!-- abs{l}-0 -->
//	  <div class="col-1"><!-- abs{r}-0 -->
//	    <span class="d-block my-1">abs<em class="text-secondary">r</em>-0</span>
//	    <div class="border border-primary position-relative wem-45em hem-45em">
//	      <div class="absr-0 bg-secondary wem-08em hem-08em position-absolute"></div>
//	    </div>
//	  </div><!-- abs{r}-0 -->
//	  <div class="col-1"><!-- abs{r}-0 abs{b}-0 -->
//      <div class="d-block my-1">
//	      <span><em class="text-secondary">r</em>-0</span>
//	      <span><em class="text-secondary">b</em>-0</span>
//      </div>
//	    <div class="border border-primary position-relative wem-45em hem-45em">
//	      <div class="absr-0 absb-0 bg-secondary wem-08em hem-08em position-absolute"></div>
//	    </div>
//	  </div><!-- abs{r}-0 abs{b}-0 -->
//	</div><!-- row -->
// 
// Styleguide UTILS.AFTER.ABSOLUTE.SIMPLE

// COMPLEX
//
// Сложные случаи
//
// ---------------------------------
// Шаблон: **absTrl** {X/Y} - '{n}?'
// ---
//
// transform: translate(X, Y)
// 
// transform: translate(-X, -Y)
// 
// - _n_--not (minus)
// 
//  Markup:
//	<div class="row">
//	  <div class="col-2"><!-- absTrl{Y}-n100 -->
//	    <span class="d-block my-1">absTrl<em class="text-secondary font-weight-bold">Y</em>-n100</span>
//	    <div class="border border-primary position-relative wem-45em hem-45em"></div>
//      <div class="bg-primary wem-08em hem-08em position-absolute"></div>
//      <div class="absTrlY-n100 bg-secondary wem-08em hem-08em position-absolute"></div>
//	  </div><!-- abs{TrlY} - n100 -->
//	  <div class="col-2"><!-- abs{t}-0 -->
//	    <span class="d-block my-1">abs<em class="text-secondary">t</em>-0</span>
//	    <div class="border border-primary position-relative wem-45em hem-45em">
//	      <div class="abst-0 bg-secondary wem-08em hem-08em position-absolute"></div>
//	    </div>
//	  </div><!-- abs{t}-0 -->
//	  <div class="col-2"><!-- abs{l}-0 -->
//	    <span class="d-block my-1">abs<em class="text-secondary">l</em>-0</span>
//	    <div class="border border-primary position-relative wem-45em hem-45em">
//	      <div class="absl-0 bg-secondary wem-08em hem-08em position-absolute"></div>
//	    </div>
//	  </div><!-- abs{l}-0 -->
//	  <div class="col-2"><!-- abs{r}-0 -->
//	    <span class="d-block my-1">abs<em class="text-secondary">r</em>-0</span>
//	    <div class="border border-primary position-relative wem-45em hem-45em">
//	      <div class="absr-0 bg-secondary wem-08em hem-08em position-absolute"></div>
//	    </div>
//	  </div><!-- abs{r}-0 -->
//	  <div class="col-2"><!-- abs{r}-0 abs{b}-0 -->
//      <div class="d-block my-1">
//	      <span><em class="text-secondary">r</em>-0</span>
//	      <span><em class="text-secondary">b</em>-0</span>
//      </div>
//	    <div class="border border-primary position-relative wem-45em hem-45em">
//	      <div class="absr-0 absb-0 bg-secondary wem-08em hem-08em position-absolute"></div>
//	    </div>
//	  </div><!-- abs{r}-0 abs{b}-0 -->
//	</div><!-- row -->
// 
// Styleguide UTILS.AFTER.ABSOLUTE.COMPLEX

// -----------------------------------------
// %
// -----------------------------------------

@use 'sass:map';

// ---------------------
// WORK
// ---------------------
$work: (
	"not_responsible": true,
	"responsible": true,
	"bootstrap": false,
);

@if map.get($work, "not_responsible") {
	// ---------------------------
	// Not responsible (не подстраивается под экран)
	// ---------------------------
	@each $point in $myAbsPoints {
		$str: $point + unquote("");       // number -> string
		$mark: str-replace($str, '%');    // replace '%'

		@if $mark != '50' {

			.abst-#{$mark} {                  // .abst-0 
				top: $point;                    // top: 0%
			};

			.absr-#{$mark} {                  // .absr-0 
				right: $point;                  // right: 0%
			};

			.absb-#{$mark} {                  // .absb-0 
				bottom: $point;                 // bottom: 0%
			};

			.absl-#{$mark} {                  // .absl-0 
				left: $point;                   // left: 0%
			}
			
			@if $mark != '0' {

				.abst-n#{$mark} {                  // .abst-n5 
					top: -$point;                    // top: -5%
				};

				.absr-n#{$mark} {                  // .absr-n5 
					right: -$point;                  // right: -5%
				};

				.absb-n#{$mark} {                  // .absb-n5 
					bottom: -$point;                 // bottom: -5%
				};

				.absl-n#{$mark} {                  // .absl-n5 
					left: -$point;                   // left: -5%
				}

				.absTrlX-n#{$mark} {
					transform: translate(-$point, 0);
				}

				.absTrlY-n#{$mark} {
					transform: translate(0, -$point);
				}

			} // end @if !=0

			.absTrlX-#{$mark} {
				transform: translate($point, 0);
			}

			.absTrlY-#{$mark} {
				transform: translate(0, $point);
			}

		} @else { // end @if != 50

			.abst-#{$mark} {                  // .abst-50 
				top: $point;                    // top: 50%
				transform: translate(0, -50%);
			};

			.absr-#{$mark} {                  // .absr-50 
				right: $point;                  // right: 50%
				transform: translate(50%, 0);
			};

			.abstr-#{$mark} {                  // .abstr-50 
				top: $point;                     // top: 50%
				right: $point;                   // right: 50%
				transform: translate(50%, -50%);
			};

			.abstl-#{$mark} {                  // .abstl-50 
				top: $point;                     // top: 50%
				left: $point;                    // left: 50%
				transform: translate(-50%, -50%);
			};

			.absb-#{$mark} {                  // .absb-50 
				bottom: $point;                 // bottom: 50%
				transform: translate(0, 50%);    
			};

			.absl-#{$mark} {                  // .absl-50 
				left: $point;                   // left: 50%
				transform: translate(-50%, 0);
			}
		} // end else
	}
}


@if map.get($work, "responsible") {
	// --------------------------
	// Responsible (Подстраивается под экран)
	// --------------------------
	@each $point in $myAbsPoints {
		$str: $point + unquote("");       // number -> string
		$mark: str-replace($str, '%');    // replace '%'

		@each $breakpoint in map.keys($grid-breakpoints) {
			@include media-breakpoint-up($breakpoint) {
				$infix: breakpoint-infix($breakpoint, $grid-breakpoints);


				@each $bp in $myBreakpointsList {
					$bp: "-" + $bp;
					@if $infix == $bp {
						@if $mark != '50' {

							.abst#{$infix}-#{$mark} {                  // .abst-md-5 
								top: $point;                             // top: 5%
							};

							.abst#{$infix}-n#{$mark} {                  // .abst-md-n5 
								top: -$point;                             // top: -5%
							};

							.absr#{$infix}-#{$mark} {                   // .absr-md-5 
								right: $point;                            // right: 5%
							};

							.absr#{$infix}-n#{$mark} {                  // .absr-md-n5 
								right: -$point;                           // right: -5%
							};

							.absb#{$infix}-#{$mark} {                   // .absb-md-5 
								bottom: $point;                           // bottom: 5%
							};

							.absb#{$infix}-n#{$mark} {                  // .absb-md-n5 
								bottom: -$point;                          // bottom: -5%
							};

							.absl#{$infix}-#{$mark} {                   // .absl-md-5 
								left: $point;                             // left: 5%
							}

							.absl#{$infix}-n#{$mark} {                  // .absl-md-n5 
								left: -$point;                            // left: -5%
							}

							.absTrlX#{$infix}-#{$mark} {                 // absTrlX-md-5
								transform: translate($point, 0);           // translate(5%, 0)
							}

							.absTrlX#{$infix}-n#{$mark} {                 // absTrlX-md-n5
								transform: translate(-$point, 0);           // translate(-5%, 0)
							}

							.absTrlY#{$infix}-#{$mark} {                   // absTrlY-md-5
								transform: translate(0, $point);             // translate(0, 5%)
							}

							.absTrlY#{$infix}-n#{$mark} {                   // absTrlY-md-n5
								transform: translate(0, -$point);             // translate(0, -5%)
							}

						} @else {                                          // 50
							.abst#{$infix}-#{$mark} {                        // abst-md-50 
								top: $point;                                   // top: 50%
								transform: translate(0, -50%);
							};

							.absr#{$infix}-#{$mark} {                         // absr-50 
								right: $point;                                  // right: 50%
								transform: translate(50%, 0);
							};

							.abstr#{$infix}-#{$mark} {                         // abstr-md-50 
								top: $point;                                     // top: 50%
								transform: translate(50%, -50%);                 // right: 50%
							};

							.abstl#{$infix}-#{$mark} {                          // abstl-md-50 
								top: $point;                                      // top: 50%
								transform: translate(-50%, -50%);                 // left: -50%
							};

							.absb#{$infix}-#{$mark} {                           // absb-50 
								bottom: $point;                                   // bottom: 50%
								transform: translate(0, 50%);                    
							};

							.absl#{$infix}-#{$mark} {                            // absl-50 
								left: $point;                                      // left: 50%
								transform: translate(-50%, 0);
							}
						}
					}
				}
			}
		}
	}
}
	
@if map.get($work, "bootstrap") {
	// -----------------------------------------
	// Bootstrap units
	// -----------------------------------------
	@each $point, $value in $spacers {
		$mark: ($point + 'bs');
		$point: $value;

		// ---------------------------
		// Not responsible (не подстраивается под экран)
		// ---------------------------

			@if $point != 0 {

				.abst-#{$mark} {                  // .abst-0 
					top: $point;                    // top: 0%
				};

				.absr-#{$mark} {                  // .absr-0 
					right: $point;                  // right: 0%
				};

				.absb-#{$mark} {                  // .absb-0 
					bottom: $point;                 // bottom: 0%
				};

				.absl-#{$mark} {                  // .absl-0 
					left: $point;                   // left: 0%
				}
			

				.abst-n#{$mark} {                  // .abst-0 
					top: -$point;                    // top: 0%
				};

				.absr-n#{$mark} {                  // .absr-0 
					right: -$point;                  // right: 0%
				};

				.absb-n#{$mark} {                  // .absb-0 
					bottom: -$point;                 // bottom: 0%
				};

				.absl-n#{$mark} {                  // .absl-0 
					left: -$point;                   // left: 0%
				}

				.absTrlX-n#{$mark} {
					transform: translate(-$point, 0);
				}

				.absTrlY-n#{$mark} {
					transform: translate(0, -$point);
				}


				.absTrlX-#{$mark} {
					transform: translate($point, 0);
				}

				.absTrlY-#{$mark} {
					transform: translate(0, $point);
				}
			} // end @if !=0
	}
}
