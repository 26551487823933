// -----------------
// СОЗДАЕТ КЛАССЫ ДЛЯ ЦВЕТОВ
// -----------------


// TEXT
// ^^^^^
@mixin colorText ($parent, $color, $value) {
  @each $alpha in $myAlpha {
    $mark: $alpha + quote("");   // number -> string
    $mark: str-replace($mark, ".", "_");  // replace "." to "_"

    #{$parent}_a#{$mark} {
      color: rgba($value, $alpha);
    }

    #{$parent}_a#{$mark}-hov {
      @include hover {
        color: rgba($value, $alpha) !important;
      }
    }
  }
}

// SVG
// ^^^^
@mixin colorFill ($parent, $color, $value) {
  @each $alpha in $myAlpha {
    $mark: $alpha + quote("");   // number -> string
    $mark: str-replace($mark, ".", "_");  // replace "." to "_"
    #{$parent}_a#{$mark} {
      fill: rgba($value, $alpha);
    }
  }
}
