@use 'sass:map';
// ----------------------------
// FROM BOOTSTRAP UTILS/SIZING
// ----------------------------

$work: (
  '3gger': false,
);

// Width and height
// ^^^^^^^^^^^^^^^^^^

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }

    @if map.get($work, "3gger") {
      // START 3gger ::::::::::::::::::::::::::::
      // Уменьшаем ширину/высоту на заданную в переменных величину
      @each $corr in $mySizeCorrect {
        $str: $corr + unquote("");           // num -> string
        $mark: str-replace($str, ".", "");   // remove dot
        $mark: str-replace($str, "-", "n");   // remove dot
        .#{$abbrev}-#{$size}#{$mark} {       // 'h-50-1em'
          #{$prop}: calc(#{$length} + #{$corr});  
        }
      }
      // END 3gger :::::::::::::::::::::::::::::::
    }
  }
}

.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }

// Viewport additional helpers

.min-vw-100 { min-width: 100vw !important; }
.min-vh-100 { min-height: 100vh !important; }

.vw-100 { width: 100vw !important; }
//.vh-100 { height: 100vh !important; } // Перенес в _sizes.scss
