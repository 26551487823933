// ----------------
// КЛАССЫ ДЛЯ OVERFLOW
// ----------------


@use 'sass:map';

// ---------------------
// WORK
// ---------------------
$work: (
	"responsible": true,
);

@if map.get($work, "responsible") {
	.overfl-y-scroll {
		overflow-y: scroll;
	}
}
