/**
* MIXIN *from sass/mixins/after/_socials.scss
* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
* Настройка значков социальных сетей
* @param $secta - id of a section ( :=> 'top-bar' )
* @param $width - ширина значка
* @param $height - высота значка
*/ 


// MIXIN 
// ^^^^^^
@include socialsDimension (
  'top-bar', 
  $mySocialsTopBarWidth,
  $mySocialsTopBarHeight,
);
