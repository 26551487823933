// ----------------
// КЛАССЫ ДЛЯ НАСТРОЙКИ Z-INDEX
// ----------------

@each $index in $myZIndex {
  $str: $index + unquote("");       // number -> string
  $mark: $str;
  .zi-#{$mark} {                  // .zi-10 
    z-index: $mark;              // z-index: 10
  }
}
