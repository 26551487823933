/**
* SCSS
* "styles.scss"
* Главный файл - менеджер
* Собирает все стили в одном файле и экспортирует в приложение
*
* СОДЕРЖАНИЕ:
*   # myVARIABLES - мои переменные. Перед BOOTSTRAP
*   # myMIXINS BEFORE BOOTSTRAP - мои миксины-дополнения-bootstrap. Перед BOOTSTRAP
*   # BOOTSTRAP - Импорт компонентов BOOTSTRAP
*     # BOOTSTRAP ESSENTIAL
*       # FUNCTIONS & VARIABLES
*       # MIXINS
*       # OTHER
*     # UTILITIES 
*     # COMPONENTS 
*   # VENDORS - Импорт стилей других приложений
*     # AOS
*   # CUSTOM - мои разработки
*     # myMIXINS
*     # myUTILS
*     # myMODULES
*/


// -----------------------
// myFUNCTIONS BEFORE BOOTSTRAP
// -----------------------
@import "./functions/functions";

// -----------------------
// myVARIABLES BEFORE BOOTSTRAP
// -----------------------
@import "./vars_before";


// ------------------------
// myMIXINS BEFORE BOOTSTRAP
// ------------------------
@import "mixins/before/mixins";

// ------------------------
// myUTILS BEFORE BOOTSTRAP
// ------------------------
@import "utils/before/utils";



// ------------------------
// BOOTSTRAP
// ------------------------

  //BOOTSTRAP ESSENTIAL
    // FUNCTIONS & VARIABLES
@import "../node_modules/bootstrap/scss/_functions";
@import "../node_modules/bootstrap/scss/_variables";
    // MIXINS
@import "../node_modules/bootstrap/scss/vendor/_rfs.scss";
@import "../node_modules/bootstrap/scss/mixins/_alert.scss";
@import "../node_modules/bootstrap/scss/mixins/_background-variant.scss"; // attention!
@import "../node_modules/bootstrap/scss/mixins/_badge.scss";
@import "../node_modules/bootstrap/scss/mixins/_border-radius.scss";
@import "../node_modules/bootstrap/scss/mixins/_box-shadow.scss";
@import "../node_modules/bootstrap/scss/mixins/_breakpoints.scss";
// @import "../node_modules/bootstrap/scss/mixins/_buttons.scss";  // замена: mixins/after/_my-bootsttap/_buttons.scss
@import "../node_modules/bootstrap/scss/mixins/_caret.scss";
@import "../node_modules/bootstrap/scss/mixins/_clearfix.scss";
@import "../node_modules/bootstrap/scss/mixins/_deprecate.scss";
@import "../node_modules/bootstrap/scss/mixins/_float.scss";
@import "../node_modules/bootstrap/scss/mixins/_forms.scss";
@import "../node_modules/bootstrap/scss/mixins/_gradients.scss";
@import "../node_modules/bootstrap/scss/mixins/_grid-framework.scss";
@import "../node_modules/bootstrap/scss/mixins/_grid.scss";
@import "../node_modules/bootstrap/scss/mixins/_hover.scss";
@import "../node_modules/bootstrap/scss/mixins/_image.scss";
@import "../node_modules/bootstrap/scss/mixins/_list-group.scss";
@import "../node_modules/bootstrap/scss/mixins/_lists.scss";
@import "../node_modules/bootstrap/scss/mixins/_nav-divider.scss";
@import "../node_modules/bootstrap/scss/mixins/_pagination.scss";
@import "../node_modules/bootstrap/scss/mixins/_reset-text.scss";
@import "../node_modules/bootstrap/scss/mixins/_resize.scss";
@import "../node_modules/bootstrap/scss/mixins/_screen-reader.scss";
@import "../node_modules/bootstrap/scss/mixins/_size.scss";
@import "../node_modules/bootstrap/scss/mixins/_table-row.scss";
@import "../node_modules/bootstrap/scss/mixins/_text-emphasis.scss";
@import "../node_modules/bootstrap/scss/mixins/_text-hide.scss";
@import "../node_modules/bootstrap/scss/mixins/_text-truncate.scss";
@import "../node_modules/bootstrap/scss/mixins/_transition.scss";
@import "../node_modules/bootstrap/scss/mixins/_visibility.scss";
    // OTHER
@import "../node_modules/bootstrap/scss/_root";
// @import "../node_modules/bootstrap/scss/_reboot";  // перенос в свои utils/after/_reboot.scss
@import "../node_modules/bootstrap/scss/_grid";
@import "../node_modules/bootstrap/scss/_type";
@import "../node_modules/bootstrap/scss/_images";

  // UTILITIES
@import "../node_modules/bootstrap/scss/utilities/align";
@import "../node_modules/bootstrap/scss/utilities/background"; // attention!
// @import "../node_modules/bootstrap/scss/utilities/borders"; // замена: utils/bootstrap/_borders.scss
@import "../node_modules/bootstrap/scss/utilities/clearfix";
@import "../node_modules/bootstrap/scss/utilities/display";
@import "../node_modules/bootstrap/scss/utilities/embed";
@import "../node_modules/bootstrap/scss/utilities/flex";
@import "../node_modules/bootstrap/scss/utilities/float";
@import "../node_modules/bootstrap/scss/utilities/overflow";
@import "../node_modules/bootstrap/scss/utilities/position";
@import "../node_modules/bootstrap/scss/utilities/screenreaders";
@import "../node_modules/bootstrap/scss/utilities/shadows";
// @import "../node_modules/bootstrap/scss/utilities/sizing"; // заменяем на свой _sizing-bootstrap.scss
@import "../node_modules/bootstrap/scss/utilities/stretched-link";
@import "../node_modules/bootstrap/scss/utilities/spacing";
@import "../node_modules/bootstrap/scss/utilities/text";
@import "../node_modules/bootstrap/scss/utilities/visibility";

  // COMPONENTS
// @import "../node_modules/bootstrap/scss/_alert";
// @import "../node_modules/bootstrap/scss/_badge";
// @import "../node_modules/bootstrap/scss/_breadcrumb";
// @import "../node_modules/bootstrap/scss/_button-group";
// @import "../node_modules/bootstrap/scss/_buttons"; // замена: modules/my-bootstrap/_buttons.scss
// @import "../node_modules/bootstrap/scss/_card";
// @import "../node_modules/bootstrap/scss/_carousel";
// @import "../node_modules/bootstrap/scss/_close";
// @import "../node_modules/bootstrap/scss/_code";
// @import "../node_modules/bootstrap/scss/_custom-forms";
// @import "../node_modules/bootstrap/scss/_dropdown";
// @import "../node_modules/bootstrap/scss/_forms";
// @import "../node_modules/bootstrap/scss/_input-group";
// @import "../node_modules/bootstrap/scss/_jumbotron";
// @import "../node_modules/bootstrap/scss/_list-group";
// @import "../node_modules/bootstrap/scss/_media";
// @import "../node_modules/bootstrap/scss/_modal"; // from my-bootstrap
// @import "../node_modules/bootstrap/scss/_navbar";
// @import "../node_modules/bootstrap/scss/_nav";
// @import "../node_modules/bootstrap/scss/_pagination";
// @import "../node_modules/bootstrap/scss/_popover";
// @import "../node_modules/bootstrap/scss/_print";
// @import "../node_modules/bootstrap/scss/_progress";
// @import "../node_modules/bootstrap/scss/_spinners";
// @import "../node_modules/bootstrap/scss/_tables";
// @import "../node_modules/bootstrap/scss/_toasts";
// @import "../node_modules/bootstrap/scss/_tooltip";
@import "../node_modules/bootstrap/scss/_transitions"; /* .collapse */


// ---------------------------------
// VENDORS
// ---------------------------------

  // Owl.carousel
  @import "../node_modules/owl.carousel/src/scss/owl.carousel.scss";
  @import "../node_modules/owl.carousel/src/scss/owl.theme.default.scss";



// ---------------------------------
// CUSTOM MY
// ---------------------------------

// -----------------------
// myVARIABLES AFTER BOOTSTRAP
// -----------------------
@import "./vars_after";


  // myMIXINS AFTER BOOTSTRAP
@import "mixins/after/mixins";


  // myUTILS AFTER
@import "utils/after/utils";

  // myMODULES
@import "modules/modules";
