// -----------------
// СОЗДАЕТ КЛАССЫ BACKGROUND РАЗНОЙ ПЛОТНОСТИ
// -----------------

// INCLUDE
// ========
$work: (
  "color": false,
  "size": true,
  "position": true,
  "origin": false,
  "clip": false,
  "gradient-linear": true,
);

// @debug "map, color #{map-get($work, "color")}";

@if map-get($work, "color") {
  // background-color
  // ^^^^^^^^^^^^^^^^^
  @each $color, $value in $myBackgroundColors {
    @each $alpha in $myAlpha {          // rgbA :: alfa-channel
      $mark: $alpha + quote("");        // number -> string
      $mark: str-replace($mark, ".");   // remove "."
      .bg-#{$color}_a#{$mark} {          // '.bg-blackA02'
        background-color: rgba($value, $alpha);
      }
    }
  }
}

@if map-get($work, "size") {
  // background-size
  // ^^^^^^^^^^^^^^^^
  @each $size in $myBackgroundSize {
    .bg-size-#{$size} {
      background-size: $size;
      background-repeat: no-repeat;
    }
  }
}

@if map-get($work, "position") {
  // background-position
  // ^^^^^^^^^^^^^^^^^^^^
  @each $name, $value in $myBackgroundPosition {
    .bg-pos-#{$name} {
      background-position: unquote($value);
      background-repeat: no-repeat;
    }
  }
}

@if map-get($work, "origin") {
  // background-origin
  // ^^^^^^^^^^^^^^^^^^^
  @each $name, $value in $myBackgroundOrigin {
    .bg-orig-#{$name} {
      background-origin: unquote($value);
    }
  }
}

@if map-get($work, "clip") {
  // background-clip
  // ^^^^^^^^^^^^^^^^^
  @each $name, $value in $myBackgroundClip {
    .bg-clip-#{$name} {
      background-clip: unquote($value);
    }
  }
}

@if map-get($work, "gradient-linear") {
  // background-gradient-linear
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^
  $grad-map: $myGradLinear_1; // from vars_after.scss

  .grad-line {
    background: linear-gradient(
      map-get($grad-map, "direction"),
      map-get($grad-map, "startColor")
      map-get($grad-map, "startPos"),
      map-get($grad-map, "endColor")
      map-get($grad-map, "endPos"),
    );
  }
}
